import React from 'react';
import { FractionGame } from './components/FractionGame';
import './App.css';

function App() {
  return (
    <div className="App">
      <FractionGame />
    </div>
  );
}

export default App; 